import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage114Avaleht from "./pages/Webpage114Avaleht";
import Webpage115Pood from "./pages/Webpage115Pood";
import Webpage116AJURVEEDA from "./pages/Webpage116AJURVEEDA";
import Webpage117KONTAKT from "./pages/Webpage117KONTAKT";
import Webpage118Konsultatsioon from "./pages/Webpage118Konsultatsioon";
import Webpage119Teraapiad from "./pages/Webpage119Teraapiad";
import Webpage120toode from "./pages/Webpage120toode";
import Webpage121Ajurveeda_soovitab from "./pages/Webpage121Ajurveeda_soovitab";
import Webpage122Ajurveeda_kehat____bid from "./pages/Webpage122Ajurveeda_kehat____bid";
import Webpage123terms from "./pages/Webpage123terms";
import Webpage124Privacy from "./pages/Webpage124Privacy";
import Webpage125Soovitused_kevadeks from "./pages/Webpage125Soovitused_kevadeks";
import Webpage126Soovitused_s__giseks from "./pages/Webpage126Soovitused_s__giseks";
import Webpage127Soovitused_talveks from "./pages/Webpage127Soovitused_talveks";
import Webpage128checkout from "./pages/Webpage128checkout";
import Webpage159Not_found from "./pages/Webpage159Not_found";
import Webpage236test from "./pages/Webpage236test";
import Webpage320esfs from "./pages/Webpage320esfs";
import Webpage347estonain_website from "./pages/Webpage347estonain_website";
import Webpage439Russian_website from "./pages/Webpage439Russian_website";

var baseURL = 'https://d2.itprojektid.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5IiwianRpIjoiZmUzYzU0MGE0M2EwN2Y5MmVmYTJlZTE5ODc1MGYyMjY4NGI4ZmI4OWU2ZjI4MGY4YTgwNDBhY2VmNjkwNjliNzc3MTJkNDA0YmUyMDhkYzYiLCJpYXQiOjE3MjY0ODQzMTQuNjgzNDA0LCJuYmYiOjE3MjY0ODQzMTQuNjgzNDEsImV4cCI6MTcyNjU3MDcxNC42NzA2NDYsInN1YiI6IjY0Iiwic2NvcGVzIjpbXX0.WFC4PUB-KOQyjyvHwvq7_y9LhPA-ctq-j7JTAJWKlKank8XplA5T8e8SU_sfhiv7N5DNTBFkQ8Weoe-2jNFmKMwQ-g-l_TSNOrxKsh2yRKsI5zsbMcFbfDU-JJfyZUTd3O9Ns0LLpVTu6HYRB5kN2JJHCBst_OLcB-4hm1khByqqXKOZwHSkzJd-F-8LmC0Vu8Wn8PsfW9puaCuliMLXKOvQM8m99JnagidfbIIEF-znkoSQNtFL-vrVWcOXXh4AnjFogw8gSkpL4SGmqXz-s4bfvGkZxnVnwSJdv7zVQoVDVh41yzZHA5Uf-gY7tHgco3LkIOXGe_5tj9TG0WYaYLISz-soKtkWuU_yrZRhw0f6vV3PFzxPBpGE2Yn9IXpLFvuXBC2lnG8mC3dyuxTQJbSQG3xyuyMTi4lTq8AJQwYfGHW7r51APbeEslosECt571blVvBxhWSQODSqVfNDbtmvmaNDtucojjFr2NLaCLu3wtbyKRTn_D8bzaGpboGfpJJp5WZSsgIYvYdHYMxNFPTzA4DqYhYJsTAdY8A9_AfkNgjLn6QdHMoAIZhhyurOxzMQy2Lis5LKqtr9aykNxDQuN2hPTE8YoJ4GnxANUH07oalMJbJ0t7q-4CsL1n8OZ5JSTnWcqpxpGS_7Vv-k3tow2w0zR8sdelSK0K-K0j8',
    'Access-api-key': "eyJpdiI6IkJCeVNJNmlKUHU2VDZDTW4xODZ2S1E9PSIsInZhbHVlIjoiQ2tmTE53ZDB0bWxNSks0U0lqWWdYQT09IiwibWFjIjoiOTVhNzQ5YTQ4YWRmMDNiMzc1MTFmMjQ5ZmE2ZWUyNzZhMGM4NTY3NjljMDdiZWNlMGNmYTRiNTI2YzBiMDQxZCIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IlFKUUZLbjFZWUtQZ20rb1VEMjlTMFE9PSIsInZhbHVlIjoiRnE1VXFnejJtUHVTdzVOR1p3ckhuUT09IiwibWFjIjoiNzY1YTljZTc0NzVjM2RiMWM1YjUwNTM5ZDI0NmMzMDNjMzU5MDdjNjIyY2Y2MWM2NGZmMTBlZWM1M2I0MDllNiIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-WPJNJFNB"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage114Avaleht />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage115Pood />} exact />;
                                                                    <Route path="/et/AJURVEEDA" element={<Webpage116AJURVEEDA />} exact />;
                                                                    <Route path="/et/kontakt" element={<Webpage117KONTAKT />} exact />;
                                                                    <Route path="/et/Konsultatsioon" element={<Webpage118Konsultatsioon />} exact />;
                                                                    <Route path="/et/Teraapiad" element={<Webpage119Teraapiad />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage120toode />} exact />;
                                                                    <Route path="/et/soovitab" element={<Webpage121Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/kehatyybid" element={<Webpage122Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/et/terms" element={<Webpage123terms />} exact />;
                                                                    <Route path="/et/privacy" element={<Webpage124Privacy />} exact />;
                                                                    <Route path="/et/soovitused_kevadeks" element={<Webpage125Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/soovitused_sygiseks" element={<Webpage126Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/soovitused_talveks" element={<Webpage127Soovitused_talveks />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage128checkout />} exact />;
                                                                    <Route path="/et/*" element={<Webpage159Not_found />} exact />;
                                                <Route path="*" element={<Webpage159Not_found />} exact />;
                                                                    <Route path="/et/test" element={<Webpage236test />} exact />;
                                                                    <Route path="/en/sef" element={<Webpage320esfs />} exact />;
                                                                    <Route path="/et/estonia" element={<Webpage347estonain_website />} exact />;
                                                                    <Route path="/ru/russ-website" element={<Webpage439Russian_website />} exact />;
                                                                
                                            <Route path="/toode/1123321" element={<Redirect to="/toode/12" />} exact />;
                                            <Route path="/tootekategooria/viis" element={<Redirect to="/tooted?category=5" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}