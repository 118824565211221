import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useAppSelector } from "../../store/hooks";
import { NavigationContainerComponent as NavigationContainerComponent_ } from "./interfaces/base";
import RenderChildren from "./RenderChildren";
import MobileNavigation from "./MobileNavigation";

const Div = styled("div")(
  null,
  (props) =>
    function (props: any) {
      return { ...props["data-style"] };
    }
);

export default function NavigationContainerComponent({
  id,
  attributes,
  childElements,
  childWebpageComponentRelations,
}) {
  const editorId = useAppSelector((state) => state.textEditor?.get("id"));
  const component = useAppSelector(
    (state) =>
      state.builder.structure.components[id] as NavigationContainerComponent_
  );

  const isBuilder = document.getElementById("BuilderContent");

  const structure = useAppSelector((state) => state.builder.structure);

  const navBreakingPoint = component.attributes.mediaBreakpoint;

  useEffect(() => {
    if (isBuilder) return;

    const element = document.getElementById(String(component.parent_id!));

    if (element) {
      element.style.position = "unset";
    }
  }, [component]);

  return (
    <>
      <style>
        {`
      @media screen and (max-width: ${
        navBreakingPoint ? navBreakingPoint : 1000
      }px) {





        .builder-component__navigation-container {
          justify-content: end;
        }


        .navigation-items-container {
          display: none!important;
        }

        .navigation-items-container-mobile {
          display: flex!important;
        }

        .navbar {
          position: unset !important;
        }

        .navbar-nav {
          display: none;
        }
        .mobile-menu-toggle {
          display: block!important;
        }
      
        .builder-component__nav {
          display: none;
        }
      
        .mobilemenutriggers {
          display: flex !important;
        }
      }
      }
    `}
      </style>

      <Div
        id={id.toString()}
        data-component-type="navigation-container"
        className={
          (attributes.classes ?? "") +
          " builder-component builder-component__navigation-container" +
          (editorId === id ? " builder-component--active" : "")
        }
        data-style={{ ...attributes.styles }}
        style={{
          display: "flex",
          alignItems: "center",
          position: "unset",
        }}
      >
        <div
          className="navigation-items-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: `10px ${
              component.attributes.gapSize
                ? component.attributes.gapSize
                : "10px"
            }`,

            justifyContent: component.attributes.justifyContent
              ? component.attributes.justifyContent
              : "end",

            width: "100%",
            pointerEvents: "none",
          }}
          onClick={(e) => {
            console.log(e, "E CLICK TRIGGERED");
            e.stopPropagation();
          }}
        >
          <RenderChildren
            key={id + "children"}
            childWebpageComponentRelations={childWebpageComponentRelations.filter(
              (componentRelation) => {
                const childComponent =
                  structure.components[componentRelation.child_id];
                if (childComponent && childComponent.type == "editor") {
                  return false;
                }
                return true;
              }
            )}
          />
        </div>

        <RenderChildren
          key={id + "children"}
          childWebpageComponentRelations={childWebpageComponentRelations.filter(
            (componentRelation) => {
              const childComponent =
                structure.components[componentRelation.child_id];
              if (childComponent && childComponent.type == "editor") {
                return true;
              }
              return false;
            }
          )}
        />

        <div
          className="mobile-menu-toggle"
          style={{ display: "none", alignItems: "center", gap: "10px" }}
        >
          <MobileNavigation
            {...component}
            navAttributes={component.attributes ? component.attributes : {}}
          />
        </div>
      </Div>
    </>
  );
}
